import IntlMessages from "../util/IntlMessages";
import React from 'react';

export const PROJECT_NAME = 'Roll Mobility';
export const BASE_URL = window.location.origin;

export const FILTER_VISIBLE = false;
export const PER_PAGE_LIMIT = 20;
export const SUBSCRIPTION_VISIBLE = false;
export const RENTAL_VISIBLE = false;
export const IS_DELIVERY_STAGE_ENABLE = true;
export const IS_ONLY_LONG_TERM_RENTAL = false;
export const IS_LONG_TERM_RENTAL_ENABLE = false;
export const IS_RENTAL_PLAN_BY_FRANCHISEE = false;
export const IS_LONG_TERM_THEME = false; // use theme-2 in css files
export const LEASING_VISIBLE = false;
export const WALLET_CONFIG_VISIBLE = true;
export const NEST_VISIBLE = true;
export const ADD_VEHICLE_INTO_NEST = false;
export const TASK_MODULE_VISIBLE = false;
export const COMMUNITY_MODE_VISIBLE = false;
export const IS_VERIFY_LABEL = true;
export const DOCUMENT_VERIFICATION_REQUIRED = true;
export const STAFF_VISIBLE = false; // This is for Franchee and Dealer
export const SUBSCRIPTION_PASS_VISIBLE = true;
export const MINIMUM_AGE_VISIBLE = false;
export const DAILY_LIGHT_ON_OFF = false;
export const SOCKET_CONNECTION = false;
export const IS_CENTRALIZED_PRICING_ENABLE = true // Franchisee Seletion required
export const FRANCHISEE_VISIBLE = true;
export const CLIENT_VISIBLE = false;
export const PARTNER_WITH_CLIENT_FEATURE = false;
export const PROMOCODE_WALLET_BALANCE_TYPE = false;
export const MULTI_CURRENCY_ENABLED = true;
export const EXPORT_EXCEL = true;
export const DISPLAY_DASHBOARD_SCOOTER_STATIC_DATA = false;
export const DISPLAY_DASHBOARD_DATA_FROM_SPECIFIC_DATE = false;
export const DISPLAY_AFTER_SPECIFIC_DATE = '2024-01-01';
export const OPERATIONAL_HOURS_VISIBLE = false;
export const IS_NOQOODY_PG = false;
export const IS_PROXYPAY_PG = false;
export const IS_SYSTEM_RECORD_DELETE_BUTTON_DISPLAY = false;
export const IS_PARKING_IMAGE_FEATURE = true;
export const IS_PARKING_FINE_FEATURE = false;
export const BATTERY_TYPE_VISIBLE = false;
export const WEB_APP_LANGUAGES = false;
export const IS_GEO_FENCE_WISE_FILTER_FEATURE = true;
export const IS_FUEL_MANAGEMENT_FEATURE = false;
export const IS_MULTI_FLOW_RIDE = false;
export const IS_PRIVATE_RIDE_ENABLE = true;
export const IS_VEHICLE_TYPES_IMAGE_ENABLE = false;
export const IS_SHOW_BATTERY_TYPE = false;
export const DEFAULT_BASE_CURRENCY = '₵';
export const DEFAULT_DISTANCE_UNIT = 'km';
export const IS_START_RIDE_FROM_ADMIN = false;
export const IS_RIDE_PROMOTION_ENABLE = false;
export const IS_VEHICLE_STATUS_TRACK_ENABLE = false;
export const RIDES_LIST_MAPVIEW_ENABLE = true; // Rides list in Map view show or rides list only Table view show.
export const IS_SECURITY_DEPOSIT_RENTAL_PLAN_WISE = false;

export let DEFAULT_MAP_CENTER = {
    lat: 5.555939,
    lng: -0.194990,
};

export const CUSTOM_MODAL_WIDTH = 720;

export const VEHICLE_TYPES = {
    SCOOTER: 1,
    BICYCLE: 2,
    BIKE: 3
};
export const FILTER_BY_VEHICLE_TYPE = [
    {
        label: <IntlMessages id="app.all" defaultMessage="All" />,
        value: 0,
        type: [VEHICLE_TYPES.SCOOTER, VEHICLE_TYPES.BICYCLE, VEHICLE_TYPES.BIKE]
    },
    { label: <IntlMessages id="app.vehicle.scooter" defaultMessage="Scooter" />, value: 1, type: [VEHICLE_TYPES.SCOOTER] },
    { label: <IntlMessages id="app.vehicle.bicycle" defaultMessage="Bicycle" />, value: 2, type: [VEHICLE_TYPES.BICYCLE] },
    { label: <IntlMessages id="app.vehicle.bike" defaultMessage="Bike" />, value: 3, type: [VEHICLE_TYPES.BIKE] }
];
export const DEFAULT_VEHICLE = FILTER_BY_VEHICLE_TYPE[1].value;

export const VEHICLE_BATTERY_TYPES = {
    GOLF_BOARD_50V: 1,
    GOLF_SCOOTER_60V: 2,
    GOLF_CART_51V: 3
};

export const VEHICLE_TYPE_FILTER = [
    { label: <IntlMessages id="app.all" defaultMessage="All" />, value: 0 },
    { label: <IntlMessages id="app.vehicle.scooter" defaultMessage="Scooter" />, value: 1, type: VEHICLE_TYPES.SCOOTER },
    { label: <IntlMessages id="app.vehicle.bicycle" defaultMessage="Bicycle" />, value: 2, type: VEHICLE_TYPES.BICYCLE },
    { label: <IntlMessages id="app.vehicle.bike" defaultMessage="Bike" />, value: 3, type: VEHICLE_TYPES.BIKE }
];
export const VEHICLE_TYPE_FILTER_FOR_EXCEL = [
    { label: "Scooter", value: 1, type: VEHICLE_TYPES.SCOOTER },
    { label: "Bicycle", value: 2, type: VEHICLE_TYPES.BICYCLE },
    { label: "Bike", value: 3, type: VEHICLE_TYPES.BIKE }
];

export const UNIT_TYPE_ARRAY = [
    { value: 1, label: DEFAULT_BASE_CURRENCY },
    { value: 2, label: '%' }
];
export const COMMISSION_TYPE_ARRAY = [
    { value: 1, label: <span><IntlMessages id="app.setUp.flat" defaultMessage="Flat" /> ({DEFAULT_BASE_CURRENCY})</span> },
    { value: 2, label: <IntlMessages id="app.setUp.percentage" defaultMessage="Percentage (%)" /> }
];

export const LANGUAGES = [
    // LTR
    { id: 'en-US', name: 'English' },
    { id: 'pt-PT', name: 'Portuguese' },
    { id: 'es-ES', name: 'Spanish' },
    { id: 'ko', name: 'Korean' },
    { id: 'it-IT', name: 'Italian (Italy)' },
    // RTL
    { id: 'ar-AE', name: 'Arabic' }
];
export const LOCALIZATION_LANGUAGES = [
    { id: 'en-US', name: 'English' },
    { id: 'de-DE', name: 'German' },
    { id: 'es-ES', name: 'Spanish' },
    { id: 'nl-BE', name: 'Dutch' },
    { id: 'it-IT', name: 'Italian' },
    { id: 'ko', name: 'Korean' },
]
export const LANGUAGES_NAME = {
    'en-US': 'English',
    'pt-PT': 'Portuguese',
    'es-ES': 'Spanish',
    'ar-AE': 'Arabic',
    'it-IT': 'Italian (Italy)',
    'ko': 'Korean'
};
export const DEFAULT_LANGUAGE = 'en-US';
export const RTL_LANGUAGE = ['ar-AE'];
export const FRANCHISEE_LABEL = <IntlMessages id="app.setUp.partnerLabel" defaultMessage="Partner" />;
export const FRANCHISEE_LABEL_STRING = "Partner";
export const FRANCHISEE_ROUTE = "partner";
export const DEALER_LABEL = <IntlMessages id="app.setUp.clientLabel" defaultMessage="Client" />;
export const DEALER_LABEL_STRING = "Client";
export const DEALER_ROUTE = "client";

export const GUEST_USER = "Guest User";
export const GUEST_USER_STRING = "Guest User";

export const SUPPORT = {
    CONTACT: 9978379402,
    EMAIL: 'develop@rohak.io'
}


export const isSuperAdminViewPartnerFare = false
export const isPartnerViewDealerFare = false
export const NEST_LABEL = <IntlMessages id="app.setUp.nestLabel" defaultMessage="Zone" />;
export const NEST_LABEL_STRING = "Zone";
export const NEST_ROUTE = "zone";

export const RIDER_LABEL = <IntlMessages id="app.setUp.riderLabel" defaultMessage="Customer" />;
export const RIDER_LABEL_STRING = "Customer";
export const RIDER_ROUTE = "customer";

export const ZONE_LABEL = <IntlMessages id="app.setUp.zoneLabel" defaultMessage="Geo Fence" />;
export const ZONE_LABEL_STRING = "Geo Fence";
export const ZONE_ROUTE = "geo-fence";

export const CITY_LABEL = <IntlMessages id="app.setUp.cityLabel" defaultMessage="City" />;
export const CITY_LABEL_STRING = "City";
export const CITY_ROUTE = "city";

export const VEHICLE_MODEL_LABEL = <IntlMessages id="app.setUp.vehicleModel" defaultMessage="Vehicle Model" />;
export const VEHICLE_MODEL_LABEL_STRING = "Vehicle Model"; // VEHICLE_MODEL
export const VEHICLE_MODEL_ROUTE = "vehicle-model";

export const SUBSCRIPTION_LABEL = <IntlMessages id="app.setUp.subscriptionLabel" defaultMessage="Booking Plan" />;
export const SUBSCRIPTION_ROUTE = "booking-plan";

export const SUBSCRIPTION_PASS_LABEL = <IntlMessages id="app.setUp.subscriptionPassLabel" defaultMessage="Subscription Pass" />;
export const SUBSCRIPTION_PASS_ROUTE = "subscription-pass";

export const RIDE_DISPUTE_LABEL = <IntlMessages id="app.setUp.rideDisputeLabel" defaultMessage="Ride Dispute" />;

export const NOTIFICATION = <IntlMessages id="app.setUp.notification" defaultMessage="Notification" />;

export const RIDES = <IntlMessages id="app.setUp.Rides" defaultMessage="Rides" />;